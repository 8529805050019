import React from "react"

type ButtonProps = {
  to: string
  children: string
}

const GradientButton = ({ to, children }: ButtonProps) => {
  return (
    <a href={to}>
      <div
        className={`flex flex-row items-center rounded-full max-sm:w-min mr-2 whitespace-nowrap bg-gradient-to-r from-white to-gradient-light-purple py-2 px-4 font-medium text-black`}
      >
        {children}
      </div>
    </a>
  )
}

export default GradientButton
