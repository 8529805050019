import React, { HTMLProps } from "react"
import GradientText from "./landing-page/gradient-text"

type TextProps = {
  className?: HTMLProps<HTMLElement>["className"] | undefined
  simpleBold?: boolean | false
  children: any
}

const CustomText = ({ children, className, simpleBold }: TextProps) => {
  const parts: any[] = []
  var remainingText: string = children
  var printText
  var textPart
  var isGradient: boolean
  var isColored: boolean

  while (remainingText != "") {
    isGradient = remainingText.startsWith("*")
    isColored = remainingText.startsWith("#")

    if (isGradient || isColored) {
      remainingText = remainingText.substring(1)
    }

    if (remainingText.includes("*")) {
      printText = remainingText.substring(0, remainingText.indexOf("*"))
      remainingText = remainingText.substring(remainingText.indexOf("*"))

      if (isGradient) {
        if (remainingText.startsWith("**")) {
          printText = printText + "*"
          remainingText = remainingText.substring(1)
        }
      }
    } else if (remainingText.includes("#")) {
      printText = remainingText.substring(0, remainingText.indexOf("#"))
      remainingText = remainingText.substring(remainingText.indexOf("#"))
    } else {
      printText = remainingText
      remainingText = ""
    }

    if (isGradient || isColored) {
      remainingText = remainingText.substring(1)
    }

    if (isGradient) {
      if (simpleBold) {
        textPart = (
          <span key={printText} className="font-semibold">
            {printText}
          </span>
        )
      } else {
        textPart = (
          <GradientText key={printText} className="font-semibold">
            {printText}
          </GradientText>
        )
      }
    } else if (isColored) {
      textPart = (
        <span key={printText} className="text-green-neon font-semibold">
          {printText}
        </span>
      )
    } else {
      textPart = printText
    }

    parts.push(textPart)
  }

  return (
    <text className={className}>
      {parts.map(part => {
        return part
      })}
    </text>
  )
}

export default CustomText
