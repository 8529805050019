import React, { useEffect } from "react"
import LPLayout from "../components/landing-page/lp-layout"
import { SEO } from "../components/seo"
import { useTranslation } from "react-i18next"
import CustomText from "../components/custom-text"

const FeeExplanationLayout = () => {
  const { t } = useTranslation()

  useEffect(() => {
    if (typeof window !== undefined && typeof document !== undefined) {
      // Cria o elemento de script
      const noscript = document.createElement('noscript');

      noscript.innerHTML = `
        <img src="https://ib.adnxs.com/pixie?pi=7b378119-73d3-4c7a-a8dc-79d1256b713b&e=PageView&script=0" width="1" height="1" style="display:none"/>
      `;

      document.body.insertBefore(noscript, document.body.firstChild);

      const script = document.createElement('script');

      // Define o conteúdo do script
      script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-P23GV22X63');

        !function(e,i){if(!e.pixie){var n=e.pixie=function(e,i,a){n.actionQueue.push({action:e,actionValue:i,params:a})};n.actionQueue=[];var a=i.createElement("script");a.async=!0,a.src="//acdn.adnxs.com/dmp/up/pixie.js";var t=i.getElementsByTagName("head")[0];t.insertBefore(a,t.firstChild)}}(window,document);
        pixie('init', '7b378119-73d3-4c7a-a8dc-79d1256b713b');

        pixie('event', 'PageView');
      `;

      // Insere o script no início do body
      document.body.insertBefore(script, document.body.firstChild);

      // Limpeza ao desmontar o componente
      return () => {
        document.body.removeChild(script);
        document.body.removeChild(noscript);
      };
    }
  }, []);  // O array vazio significa que este useEffect só é executado uma vez após o primeiro render

  return (
    <LPLayout>
      <div className="absolute bg-gradient-to-b from-gradient-purple to-black w-full h-full z-0"></div>
      <main className="flex flex-col items-justify z-10 xs:mt-36 md:mt-44 xs:mb-36 md:mb-44 px-[2.5rem] sm:px-[0.5rem] md:px-[5rem] lg:px-[10rem] xl:px-[15rem]">
        <h1 className="font-poppins font-semibold text-[40px] lg:text-[60px]">{t("feePageTitle")}</h1>
        <br /><br />
        <h2 className="font-poppins font-semibold text-[30px] lg:text-[40px]">{t("tradeFeeSubtitle")}</h2>
        <br />
        <p className="font-poppins text-[15px] lg:text-[20px] text-justify">
            <CustomText>{t("tradeFeeWithoutMinDGTA")}</CustomText>
        </p>
        <br />
        <ul className="list-disc font-poppins text-[15px] lg:text-[18px] text-justify px-[1.5rem]">
            <li><CustomText simpleBold={true}>{t("tradeFeeWithoutMinBullet")}</CustomText></li>
        </ul>
        <br />
        <p className="font-poppins text-[15px] lg:text-[20px] text-justify">
            <CustomText simpleBold={true}>{t("tradeFeeExample")}</CustomText>
        </p>
        <br /><br />

        <h3 className="font-poppins font-semibold text-[25px] lg:text-[25px]">{t("makerAndTaker")}</h3>
        <p className="font-poppins text-[15px] lg:text-[20px] text-justify">
            <CustomText simpleBold={true}>{t("makerAndTakerResume")}</CustomText>
        </p>
        <br />
        <p className="font-poppins text-[15px] lg:text-[20px] text-justify">
            <CustomText simpleBold={true}>{t("makerAndTakerOrderTypes")}</CustomText>
        </p>

        <br /><br /><br />

        <h2 className="font-poppins font-semibold text-[30px] lg:text-[40px]">{t("withdrawFeeSubtitle")}</h2>
        <br />
        <p className="font-poppins text-[15px] lg:text-[20px] text-justify">
          {t("withdrawFeeParagraph")}
        </p>
        <br /><br />
        
        <h3 className="font-poppins font-semibold text-[20px] lg:text-[30px]">{t("howDoesItWork")}</h3>
        <p className="font-poppins text-[15px] lg:text-[20px] text-justify">
            <CustomText simpleBold={true}>{t("withdrawFeeExplanation")}</CustomText>
        </p>
        <br />
        <p className="font-poppins text-[15px] lg:text-[20px] text-justify">
            <CustomText simpleBold={true}>{t("withdrawFeeImportant")}</CustomText>
        </p>
        <br /><br />

        <h3 className="font-poppins font-semibold text-[30px]">{t("examples")}</h3>

        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right font-poppins">
            <thead className="text-[15px] uppercase border-2 text-center">
              <tr>
                <th scope="col" className="px-6 py-3 border-2">
                  {t("crypto")}
                </th>
                <th scope="col" className="px-6 py-3 border-2">
                  {t("blockchainFee")}
                </th>
                <th scope="col" className="px-6 py-3 border-2">
                  {t("totalFee")}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-2 dark:bg-black dark:border-black">
                <th scope="row" className="px-6 py-4 font-normal border-2">
                  {t("cryptoCase1")}
                </th>
                <td className="px-6 py-4 border-2">
                  {t("blockchainFeeCase1")}
                </td>
                <td className="px-6 py-4 border-2">
                  {t("totalFeeCase1")}
                </td>
              </tr>
              <tr className="border-2 dark:bg-black dark:border-black">
                <th scope="row" className="px-6 py-4 font-normal border-2">
                  {t("cryptoCase2")}
                </th>
                <td className="px-6 py-4 border-2">
                  {t("blockchainFeeCase2")}
                </td>
                <td className="px-6 py-4 border-2">
                  {t("totalFeeCase2")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>
    </LPLayout>
  )
}

export const Head = () => <SEO title="Fees" pathname="fees" pageType="CollectionPage" />

export default FeeExplanationLayout
