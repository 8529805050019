import React from "react"
import Footer, { FooterColor } from "../footer"
import LpNavbar, { NavbarColor } from "./lp-navbar"
import IntercomWidget from "../intercom-widget"

type Props = {
  color?: LayoutColor | null
  children: React.ReactNode
}

export enum LayoutColor {
  light,
  dark,
  transparent,
}

const LPLayout = ({ color, children }: Props, {}) => {
  var footerColor: FooterColor
  var navbarColor: NavbarColor

  switch (color) {
    case LayoutColor.light:
      footerColor = FooterColor.light
      navbarColor = NavbarColor.light
      break
    case LayoutColor.transparent:
      footerColor = FooterColor.transparent
      navbarColor = NavbarColor.transparent
      break

    default:
      footerColor = FooterColor.dark
      navbarColor = NavbarColor.dark
      break
  }

  return (
    <div
      className={`relative flex min-h-screen flex-col justify-between ${
        color == null ||
        color == LayoutColor.transparent ||
        color == LayoutColor.dark
          ? "bg-black text-white"
          : "bg-white text-black"
      } `}
    >
      <LpNavbar color={navbarColor} />
      {children}
      <Footer footerColor={footerColor} />
      <IntercomWidget />
    </div>
  )
}

export default LPLayout
