import React, { HTMLProps } from "react"

type TextProps = {
  children: any
  className?: HTMLProps<HTMLElement>["className"] | null
}

const GradientText = ({ children, className }: TextProps) => {
  return (
    <b
      className={`w-min bg-clip-text bg-gradient-to-r from-white to-gradient-light-purple text-transparent ${className}`}
    >
      {children}
    </b>
  )
}

export default GradientText
