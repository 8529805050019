import React, { useState } from "react"
import { DigitraLogoIcon } from "../icons"
import GradientButton from "./gradient-button"
import LanguageSelect from "../language_select"
import CommonButton from "./common-button"
import { useTranslation } from "react-i18next"
import { DIGITRA_API_DOCS, OPEN_ACCOUNT_LINK } from "../../utils/constants"

type PageType = {
  name: string
  link: string
}

export enum NavbarColor {
  light,
  dark,
  transparent,
}

type NavbarProps = {
  color?: NavbarColor | null
}

const LpNavbar = ({ color }: NavbarProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()

  var bgColor

  switch (color) {
    case NavbarColor.light:
      bgColor = "bg-dark-soft-white"
      break
    case NavbarColor.transparent:
      bgColor = "bg-transparent"
      break
    default:
      bgColor = "bg-gradient-black"
      break
  }

  let pages: PageType[] = [
    { name: "Private & OTC", link: "/otc/" },
    { name: "Blog DgFresh", link: "/blog/" },
    { name: "API Doc", link: DIGITRA_API_DOCS },
  ]

  return (
    <header className="bg-primary-200 z-20">
      <nav className={` backdrop-blur fixed top-0 left-0 right-0 ${bgColor}`}>
        <div className="flex flex-wrap items-center justify-between mx-auto py-7 px-1 vs:px-5 max-w-8xl">
          <button
            onClick={() => setIsOpen(value => !value)}
            data-collapse-toggle="navbar-dropdown"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden"
            aria-controls="navbar-dropdown"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>

          {/* ==== Logo Image ==== */}
          <a href="/" aria-label="Digitra home">
            <DigitraLogoIcon
              className={` w-40 h-8 ${
                color == null ||
                color == NavbarColor.transparent ||
                color == NavbarColor.dark
                  ? "fill-white"
                  : "fill-black"
              }`}
            />
          </a>

          <div className="md:hidden">
            {/* ==== Language Options ==== */}
            <LanguageSelect />
          </div>

          {/* ==== Links ==== */}
          <div
            className={`${
              isOpen ? "flex flex-col items-center" : "hidden"
            } w-full md:block md:w-auto`}
            id="navbar-dropdown"
          >
            <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 md:flex-row md:space-x-8 md:mt-0 md:border-0 items-center ">
              {pages.map(page => (
                <li key={page.name}>
                  <a
                    href={page.link}
                    className="text-sm block py-2 pl-2 pr-2 rounded font-medium hover:text-light-purple"
                    aria-current="page"
                  >
                    {page.name}
                  </a>
                </li>
              ))}
            </ul>
            <div className="w-min md:hidden">
              <GradientButton to="https://onelink.to/appdigitra">
                Baixe o app
              </GradientButton>
            </div>
          </div>

          <div className="flex">
            <div className="hidden lg:block">
              <CommonButton to="https://app.digitra.com/">
                Login
              </CommonButton>
            </div>

            <div className="hidden lg:block">
              <GradientButton to={OPEN_ACCOUNT_LINK}>
                {t("openAccount")}
              </GradientButton>
            </div>
          </div>

          <div className="hidden md:block">
            {/* ==== Language Options ==== */}
            <LanguageSelect />
          </div>
        </div>
      </nav>
    </header>
  )
}

export default LpNavbar
