import * as React from "react"
import { useSiteMetadata } from "../graphql/get_site_metadata"
import removeMarkdown from "remove-markdown"

type SeoProps = {
  title?: null | undefined | any
  description?: null | undefined | any
  pathname?: null | undefined | any
  readingTime?: null | undefined | any
  thumbnail?: null | undefined | any
  publishedAt?: null | undefined | any
  pageType?: null | undefined | any
  children?: null | undefined | any
}

export const SEO = ({ title, description, pathname, readingTime, thumbnail, publishedAt, pageType, children }: SeoProps) => {
  const { title: defaultTitle, description: defaultDescription, image, siteUrl, twitterUsername } = useSiteMetadata()

  let plainTextDescription
  let fullURLimg = siteUrl
  let fullURL    = siteUrl

  if (description) {
    plainTextDescription = removeMarkdown(description)
  } else {
    plainTextDescription = defaultDescription
  }


  if (thumbnail && thumbnail !== "") {
    fullURLimg = fullURLimg  + thumbnail
  } else {
    fullURLimg = fullURLimg.endsWith('/') ? fullURLimg : `${fullURLimg}/`
    fullURLimg = fullURLimg + image
  }

  if (pathname) {
    const cleanedPathname = pathname.replace(/^\/|\/$/g, "");
    fullURL = `${fullURL}/${cleanedPathname}/`;
  } else {
    // Se estamos na home (pathname não fornecido), garantimos a barra ao final
    fullURL = `${fullURL}/`;
  }

  const seo = {
    title: title || defaultTitle,
    description: plainTextDescription,
    image: fullURLimg,
    url: fullURL,
    pubDate: publishedAt,
    twitterUsername,
    pageType
  }
  const canonicalURL = fullURL.endsWith('/') ? fullURL : `${fullURL}/`;

  // pageType pode ser "Article" para artigo e "CollectionPage" para os cards
  let currentDate = new Date().toISOString().split("T")[0];
  return (
    <>
        <meta charSet="UTF-8" />
        <meta name="description" content={seo.description}></meta>
        <meta property="og:locale" content="pt_BR" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={`${seo.title} • Digitra.com`} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:url" content={seo.url} />
        <meta property="og:site_name" content="Digitra.com" />
        <meta property="og:image" content={seo.image} />
        <meta property="og:image:alt" content={seo.title} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="721" />

        <title>{seo.title}</title>
        <meta name="twitter:image" content={seo.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:url" content={seo.url} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:creator" content={seo.twitterUsername} />
        <meta name="twitter:label1" content="Oferecido por" />
        <meta name="twitter:data1" content="Digitra.com" />
        <meta name="twitter:label2" content="Est. tempo de leitura" />
        <meta name="twitter:data2" content={readingTime ? readingTime + " min." : "~3 min."} />

        <link
          rel="alternate"
          type="application/rss+xml"
          title="RSS Feed for https://www.digitra.com/"
          href="/rss.xml"
        />
        {/* // TODO: Comment this fuck line in DEV - POHA, Northon. Tá escrito COMENTE. */}
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        {/* // TODO: uncomment this fuck line in DEV - PoHa, Northon! Tá escrito PRA DESCOMENTAR - EU AVISEI, poha! */}
        {/* <meta name="robots" content="noindex" /> */}

        <link rel="canonical" href={canonicalURL} />

        {pageType === "Article" && publishedAt && (
          <>
          <meta property="article:published_time" content={seo.pubDate}></meta>
          <meta property="article:modified_time" content={seo.pubDate}></meta>
          <script type="application/ld+json">
            {JSON.stringify({
               "@context": "http://schema.org",
               "@type": seo.pageType,
               "mainEntityOfPage": {
                 "@type": "WebPage",
                 "@id": seo.url
               },
               "headline": seo.title,
               "description": seo.description,
               "image": seo.image,
               "author": {
                 "@type": "Person",
                 "name": "Digitra"
               },
               "datePublished": seo.pubDate,
               "dateModified": seo.pubDate,
               "publisher": {
                 "@type": "Organization",
                 "name": "Digitra.com",
                 "logo": {
                   "@type": "ImageObject",
                   "url": "/logo-digitra.png"
                 }
               }
              })}
            </script>
          </>
        )}

        {pageType === "CollectionPage" && (
          <>
          <meta property="article:modified_time" content={currentDate}></meta>
          <script type="application/ld+json">
            {JSON.stringify({
               "@context": "http://schema.org",
               "@type": seo.pageType,
               "mainEntityOfPage": {
                 "@type": "WebPage",
                 "@id": seo.url
               },
               "headline": seo.title,
               "description": seo.description,
               "image": seo.image,
               "author": {
                 "@type": "Person",
                 "name": "Digitra" // Pode ser adaptado para aceitar outros autores, se necessário
               },
               "dateModified": currentDate, // Adicione a data de modificação da postagem, se houver
               "publisher": {
                 "@type": "Organization",
                 "name": "Digitra.com",
                 "logo": {
                   "@type": "ImageObject",
                   "url": "/logo-digitra.png" // Substitua pela URL do logo do seu site
                 }
               }
              })}
            </script>
          </>
        )}
        {/* Ambas as imagens são necessárias (png e svg, para ponts diferentes) */}
        <link rel="icon" href="/digitra-logo.png" />

        {children}
    </>
  )
}